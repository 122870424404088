import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { config } from "../setting";
import style from "./FullPost.module.css";
import Comment from "./Comment";
import parse from "html-react-parser";
import Dialog from "../layout/PostCreateDialog";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import {
  ClassicEditor,
  AccessibilityHelp,
  Autoformat,
  AutoImage,
  AutoLink,
  Autosave,
  Bold,
  CloudServices,
  Code,
  CodeBlock,
  Essentials,
  Heading,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  Paragraph,
  SelectAll,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextTransformation,
  Undo,
} from "ckeditor5";

import "ckeditor5/ckeditor5.css";

const FullPost = (props) => {
  const { id, user, userId } = useParams();
  const [postInfo, setPostInfo] = useState({});
  const [commentList, setCommentList] = useState([]);
  const [comments, setComments] = useState("");
  const [postAuthor, setPostAuthor] = useState("");

  const [isUpdate, setisUpdate] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  const [commentError, setCommentsError] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [updateTitle, setUpdateTitle] = useState("");
  const [udpateContent, setUpdateContent] = useState("");
  const editorContainerRef = useRef(null);
  const editorRef = useRef(null);
  const [isLayoutReady, setIsLayoutReady] = useState(false);
  const [canEdit, setCanEdit] = useState(false)

  const editPost = async () => {
    await axios
      .put(`${config.url.API_URL}/community/edit_post/${id}`, {
        title: updateTitle,
        content: udpateContent,
      })
      .then((res) => {
        var postInfoUpdate = res.data;
        postInfoUpdate.hatPatient = postInfo.hatPatient;
        postInfoUpdate.hatStaff = postInfo.hatStaff;
        console.log(postInfoUpdate);
        setPostInfo(postInfoUpdate);
        setIsOpen(false);
        setUpdateContent(res.data.content);
        setUpdateTitle(res.data.title);
      });
  };

  const getPostInfo = async () => {
    await axios
      .get(`${config.url.API_URL}/community/post_info/${id}`)
      .then(async (res) => {
        console.log(res.data);
        setPostInfo(res.data);
        setUpdateTitle(res.data.title);
        setUpdateContent(res.data.content);
        if (res.data.comment.length > 0) {
          setCommentList(res.data.comment);
          setisUpdate(false);
        }
      });
  };

  const getUserInfo = async () => {
    if (user == "admin") {
      await axios
        .get(`${config.url.API_URL}/hat_staff/${userId}`)
        .then(async (res) => {
          //   console.log(res.data);
          setUserInfo(res.data);
        });
    } else {
      await axios
        .get(`${config.url.API_URL}/hat_petient/${userId}`)
        .then(async (res) => {
          // console.log(res.data);
          setUserInfo(res.data);
        });
    }
  };

  const sendCommet = async () => {
    if (comments != "") {
      await axios
        .post(`${config.url.API_URL}/community/add_comment`, {
          postId: id,
          user_group: user,
          userID: userId,
          content: comments,
        })
        .then((response) => {
          // console.log(response.data);
          var commentPackage = response.data;
          commentPackage.hatPatient = { FIRST_NAME: userInfo.FIRST_NAME };
          commentPackage.hatStaff = { FIRST_NAME: userInfo.FIRST_NAME };
          setCommentList([response.data, ...commentList]);
          setComments("");
          setCommentsError("");
        })
        .catch((err) => {});
    } else {
      setCommentsError("Comment Post can not be empty");
    }
  };

  const showAthorName = () => {
    var Postauthor = postInfo;
    if (Object.keys(Postauthor).length !== 0) {
      if (postInfo.authortype == "admin") {
        setPostAuthor(Postauthor.hatStaff.FIRST_NAME);
        // setCanEdit(true)
      } else {
        if (user == "admin") {
          setPostAuthor(Postauthor.hatPatient.FIRST_NAME);
          // setCanEdit(true)
        } else {
          setPostAuthor("Anonymous");
          // setCanEdit(false)
        }
      }
    }
  };

  const showEditBtn = () =>{
    console.log(user)
    if(user == "admin"){
       setCanEdit(true)
    }else if (postInfo.authortype !== "admin" && postInfo.authorID == userId ){
      setCanEdit(true)
    }else{
      setCanEdit(false)
    }
  }

  useEffect(() => {
    getPostInfo();
    showAthorName();
    showEditBtn()
    getUserInfo();
    setIsLayoutReady(true);

    return () => setIsLayoutReady(false);
  }, []);

  useEffect(() => {
    if (!isUpdate) {
      showAthorName();
      // setisUpdate(true)
    }
  }, [isUpdate, postInfo]);

  const editorConfig = {
    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "selectAll",
        "|",
        "heading",
        "|",
        "bold",
        "italic",
        "code",
        "|",
        "link",
        "insertTable",
        "codeBlock",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "accessibilityHelp",
      ],
      shouldNotGroupWhenFull: false,
    },
    plugins: [
      AccessibilityHelp,
      Autoformat,
      AutoImage,
      AutoLink,
      Autosave,
      Bold,
      CloudServices,
      // Code,
      // CodeBlock,
      Essentials,
      Heading,
      ImageBlock,
      ImageCaption,
      ImageInline,
      ImageInsertViaUrl,
      ImageResize,
      ImageStyle,
      ImageTextAlternative,
      ImageToolbar,
      ImageUpload,
      Italic,
      Link,
      LinkImage,
      List,
      ListProperties,
      Paragraph,
      SelectAll,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      TextTransformation,
      Undo,
    ],
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        {
          model: "heading5",
          view: "h5",
          title: "Heading 5",
          class: "ck-heading_heading5",
        },
        {
          model: "heading6",
          view: "h6",
          title: "Heading 6",
          class: "ck-heading_heading6",
        },
      ],
    },
    image: {
      toolbar: [
        "toggleImageCaption",
        "imageTextAlternative",
        "|",
        "imageStyle:inline",
        "imageStyle:wrapText",
        "imageStyle:breakText",
        "|",
        "resizeImage",
      ],
    },
    // initialData:
    // 	'<h2>Congratulations on setting up CKEditor 5! 🎉</h2>\n<p>\n    You\'ve successfully created a CKEditor 5 project. This powerful text editor will enhance your application, enabling rich text editing\n    capabilities that are customizable and easy to use.\n</p>\n<h3>What\'s next?</h3>\n<ol>\n    <li>\n        <strong>Integrate into your app</strong>: time to bring the editing into your application. Take the code you created and add to your\n        application.\n    </li>\n    <li>\n        <strong>Explore features:</strong> Experiment with different plugins and toolbar options to discover what works best for your needs.\n    </li>\n    <li>\n        <strong>Customize your editor:</strong> Tailor the editor\'s configuration to match your application\'s style and requirements. Or even\n        write your plugin!\n    </li>\n</ol>\n<p>\n    Keep experimenting, and don\'t hesitate to push the boundaries of what you can achieve with CKEditor 5. Your feedback is invaluable to us\n    as we strive to improve and evolve. Happy editing!\n</p>\n<h3>Helpful resources</h3>\n<ul>\n    <li>📝 <a href="https://orders.ckeditor.com/trial/premium-features">Trial sign up</a>,</li>\n    <li>📕 <a href="https://ckeditor.com/docs/ckeditor5/latest/installation/index.html">Documentation</a>,</li>\n    <li>⭐️ <a href="https://github.com/ckeditor/ckeditor5">GitHub</a> (star us if you can!),</li>\n    <li>🏠 <a href="https://ckeditor.com">CKEditor Homepage</a>,</li>\n    <li>🧑‍💻 <a href="https://ckeditor.com/ckeditor-5/demo/">CKEditor 5 Demos</a>,</li>\n</ul>\n<h3>Need help?</h3>\n<p>\n    See this text, but the editor is not starting up? Check the browser\'s console for clues and guidance. It may be related to an incorrect\n    license key if you use premium features or another feature-related requirement. If you cannot make it work, file a GitHub issue, and we\n    will help as soon as possible!\n</p>\n',
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: "https://",
      decorators: {
        toggleDownloadable: {
          mode: "manual",
          label: "Downloadable",
          attributes: {
            download: "file",
          },
        },
      },
    },
    list: {
      properties: {
        styles: true,
        startIndex: true,
        reversed: true,
      },
    },
    placeholder: "Type or paste your content here!",
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableProperties",
        "tableCellProperties",
      ],
    },
  };

  return (
    <div className={style.FullPostContainer}>
      <h1>{postInfo.title}</h1>
      <div
        style={{ marginTop: "20px", marginBottom: "20px" }}
        className={style.authorAndEdit}
      >
        <p>author : {postAuthor}</p>
        {canEdit == true ? 
        <div style={{ textAlign: "right" }}>
          <button className={style.editBtn} onClick={(e) => setIsOpen(!isOpen)}>
            edit post
          </button>
          <Dialog isOpen={isOpen} onClose={(e) => setIsOpen(!isOpen)}>
            <div>
              <div className="input-group mb-3">
                <span
                  className="input-group-text"
                  id="inputGroup-sizing-default"
                >
                  Title
                </span>
                <input
                  value={updateTitle}
                  onChange={(e) => setUpdateTitle(e.target.value)}
                  type="text"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-default"
                />
              </div>
              <div className="main-container">
                <div
                  className="editor-container editor-container_classic-editor"
                  ref={editorContainerRef}
                >
                  <div className="editor-container__editor ck-editor__editable_inline">
                    <div ref={editorRef}>
                      {isLayoutReady && (
                        <CKEditor
                          style={{ height: "300px" }}
                          editor={ClassicEditor}
                          config={editorConfig}
                          data={udpateContent}
                          onChange={(s, editor) => {
                            const data = editor.getData();
                            setUpdateContent(data);
                          }}
                          onInit={(editor) => {
                            // You can store the "editor" and use when it is needed.
                            // console.log("Editor is ready to use!", editor);
                            editor.editing.view.change((writer) => {
                              writer.setStyle(
                                "height",
                                "200px",
                                editor.editing.view.document.getRoot()
                              );
                            });
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "10px" }}>
              <button
                className={style.PostUpdateBtn}
                onClick={() => editPost()}
              >
                Edit
              </button>
              <button
                className={style.PostUpdateBtn}
                onClick={(e) => setIsOpen(!isOpen)}
              >
                Clost
              </button>
            </div>
          </Dialog>
        </div>
        : null}
      </div>

      <div>{parse(`${postInfo.content}`)}</div>
      <div>{postInfo.createdAt}</div>
      <hr />
      <div>
        <div class="form-group">
          <label for="exampleFormControlTextarea1">
            {" "}
            <b>comments</b>
          </label>
          <textarea
            className="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
            value={comments}
            onChange={(e) => {
              setComments(e.target.value);
            }}
          ></textarea>
          <div className={style.CommentBtnContainer}>
            <div>{commentError}</div>
            <div style={{ textAlign: "right", window: "300px" }}>
              <button
                onClick={() => sendCommet()}
                className={style.SendCommentBtn}
              >
                send comment
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        {commentList.length > 0 ? (
          <div>
            {commentList.map((comment) => {
              return <Comment comment={comment} />;
            })}
          </div>
        ) : null}
      </div>
      <footer className="footer"></footer>
    </div>
  );
};

export default FullPost;
